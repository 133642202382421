import React, { FC } from "react"
import Slider from 'react-slick'

const settings = {
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 4000,
  cssEase: "linear",
  variableWidth: true,
  initialSlide: 0
}

export const ApartmentSlider: FC<{data: any, name: string}> = ({data, name}) => {
  return (
    <div className="apartment-hero">
      <div className="container apartment-container position-relative">
        <Slider {...settings}>
          {data.map(image => (
              <img key={'apartement-slider' + image.fixed.src} className="img-fluid" src={image.fixed.src} />
          ))}
        </Slider>
      </div>
    </div>
  )
}
